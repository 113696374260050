import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LazyLoadEvent } from 'primeng/api';
import {
  FetchLowConfidenceScores,
  FetchLowConfidenceScoresCount,
  LowConfidenceScoreActionTypes,
  ResetLowConfidenceScoresFilter,
  UpdateLowConfidenceScore,
  UpdateLowConfidenceScoresEntityTypes,
  UpdateLowConfidenceScoresIgnored,
  UpdateLowConfidenceScoresMany,
  UpdateLowConfidenceScoresPagination,
  UpdateLowConfidenceScoresSearchTerm,
} from 'src/app/store/actions/low.confidence.score.actions';
import { Store } from '@ngrx/store';
import { 
  getLowConfidenceScoreData, 
  getLowConfidenceScoreFilterData, 
  getLowConfidenceScoreFilterState 
} from 'src/app/store';
import { ILowConfidenceScore } from 'src/app/models';
import { MultiSelect } from 'primeng/multiselect';
import { Table } from 'primeng/table';
import { Actions, ofType } from '@ngrx/effects';
import { first } from 'rxjs/operators';

@Component({
  selector: 'lowconfidencescore',
  templateUrl: './low.confidence.score.component.html',
  styleUrls: ['./low.confidence.score.component.css'],
})
export class LowConfidenceScoreComponent implements OnInit {

  @ViewChild('lowConfidenceScoresEntityTypesMultiSelect') lowConfidenceScoresEntityTypesMultiSelect: MultiSelect;
  @ViewChild('lowConfidenceScoresIgnoredStatesMultiSelect') lowConfidenceScoresIgnoredStatesMultiSelect: MultiSelect;

  // Initialize values
  lowConfidenceScoresList: ILowConfidenceScore[] = [];
  mappingsIgnoredStates: any[] = [];
  totalRecordCount: number = 0;
  isLoading: boolean = false;
  isShowError: boolean = false;
  selectedMappings: any[] = [];
  error: string;
  first: number = 0;
  rows: number = 50;
  sortField: string;
  sortOrder: number;
  queryParams: any;
  messages: any[] = [];
  selectAll: boolean = false;
  payload: any;
  selectedTypes: any;
  entityTypes: any[];
  refreshed: boolean = false;
  selectedEntityTypes: any[] = [];
  selectedIgnoredStates: any[] = [];
  searchTerm: string = '';
  @ViewChild('dt') dataTable: Table;
  constructor(
    private router: Router,
    private store: Store,
    private activeRoute: ActivatedRoute,
    private actions: Actions,
  ) {}

  ngOnInit(): void {
    // Get the low confidence state to set initial values
    this.store.select(getLowConfidenceScoreData).subscribe((data) => {
      if (data) {
        this.mappingsIgnoredStates = data.ignoredStates.map((ignoredState: string) => { return { ignoredState: ignoredState }});
        this.entityTypes = data.entityTypes.map((type: string) => { return { type: type }});
        this.isLoading = data.isLoading;
        this.lowConfidenceScoresList = data.lowConfidenceScores;
        this.totalRecordCount = data.total_input_records;
        this.isShowError = false;
        if (data.errorMessage) {
          this.isShowError = true;
          this.error = data.errorMessage;
        }
      }
    })

    // Refetch notifications every time the filter state changes
    this.store.select(getLowConfidenceScoreFilterData).subscribe((data) => {
      if (data) {
        this.selectedEntityTypes = data.entity_type === '' ? [] : data.entity_type.split(',').map((type: string) => { return { type: type }});
        this.selectedIgnoredStates = data.selectedIgnoredStates
        this.first = data.from;
        this.rows = data.size;
        this.sortField = data.sort.split(':')[0];
        this.sortOrder = data.sort.split(':')[1] === 'asc' ? 1 : -1;
        this.searchTerm = data.query;
        this.store.dispatch(new FetchLowConfidenceScores(data));
        this.store.dispatch(new FetchLowConfidenceScoresCount());
      }
    });
  }

  applyTableFilters(event: LazyLoadEvent) {
    this.store.dispatch(new UpdateLowConfidenceScoresPagination({ from: event.first, size: event.rows, sort: event.sortField !== null ? `${event.sortField}:${event.sortOrder === -1 ? "desc" : "asc"}` : '' }));
  }

  setEntityTypes(filters: any[]) {
    this.selectedEntityTypes = filters;
  }

  applyEntityTypes() {
    this.store.dispatch(new UpdateLowConfidenceScoresEntityTypes({ entity_type: this.selectedEntityTypes.map((filter: any) => filter.type).join(',') }));
    this.lowConfidenceScoresEntityTypesMultiSelect.hide();
  }

  setIgnoredStates(filters: any[]) {
    this.selectedIgnoredStates = filters;
  }

  applyIgnoredStates() {
    let ignored_states = this.selectedIgnoredStates.map((filter: any) => filter.ignoredState)
    if ((ignored_states.includes("Show Valid") && ignored_states.includes("Show Ignored")) || !ignored_states.includes("Show Valid") && !ignored_states.includes("Show Ignored")) {
      this.store.dispatch(new UpdateLowConfidenceScoresIgnored({ ignored: null, selectedIgnoredStates: this.selectedIgnoredStates}));
    } else if (ignored_states.includes("Show Valid") && !ignored_states.includes("Show Ignored")) {
      this.store.dispatch(new UpdateLowConfidenceScoresIgnored({ ignored: "false", selectedIgnoredStates: this.selectedIgnoredStates}));
    } else if (!ignored_states.includes("Show Valid") && ignored_states.includes("Show Ignored")) {
      this.store.dispatch(new UpdateLowConfidenceScoresIgnored({ ignored: "true", selectedIgnoredStates: this.selectedIgnoredStates}));
    }
    this.lowConfidenceScoresIgnoredStatesMultiSelect.hide();
  }

  applySearchTerm(searchTerm: string) {
    this.searchTerm = searchTerm;
    this.store.dispatch(new UpdateLowConfidenceScoresSearchTerm({ query: searchTerm }));
  }

  resetFilters() {
    this.dataTable.reset();
    this.store.dispatch(new ResetLowConfidenceScoresFilter());
  }

  hasLength(data: any) {
    if (data) {
      return data.length > 0;
    }
    return false;
  }

  // removes error popup showing on the screen
  closeError() {
    this.error = null;
    this.isShowError = false;
  }

  showLowConfidenceScoreDetails(record: ILowConfidenceScore) {
    this.router.navigate([`/confidencescores/${record.id}`]);
  }

  ignoreSelectedMappings() { 
    this.store.dispatch(new UpdateLowConfidenceScoresMany(
      {
        mappingIds: this.selectedMappings.map((mapping: any) => mapping.id),
        normalizationMappingUpdate: { 
          ignored: true, 
          updatedBy: JSON.parse(localStorage.getItem('user')).displayName 
        }
      }
    ));

    // when mapping approval is successful
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManySuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `${data.payload.length} mappings have been ignored successfully`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getLowConfidenceScoreFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchLowConfidenceScores(data));
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedMappings = [];
          });
        }, 2000);
      });
    
    // when mapping approval fails
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to ignore mappings');
      });
  }

  makeSelectedMappingsActive() { 
    this.store.dispatch(new UpdateLowConfidenceScoresMany(
      {
        mappingIds: this.selectedMappings.map((mapping: any) => mapping.id),
        normalizationMappingUpdate: { 
          ignored: false,
          updatedBy: JSON.parse(localStorage.getItem('user')).displayName
        }
      }
    ));
    
    // when mapping approval is successful
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManySuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `${data.payload.length} mappings have made active successfully`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getLowConfidenceScoreFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchLowConfidenceScores(data));
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedMappings = [];
          });
        }, 2000);
      });
    
    // when mapping approval fails
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to make mappings active');
      });
  }

  makeActive(event: Event, mappingId: string) {
    event.stopPropagation();
    this.store.dispatch(new UpdateLowConfidenceScore({ mappingId: mappingId, normalizationMappingUpdate: { ignored: false, updatedBy: JSON.parse(localStorage.getItem('user')).displayName } }));
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `Mapping is now active`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getLowConfidenceScoreFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchLowConfidenceScores(data));
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedMappings = [];
          });
        }, 3000);
      });
    
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to make mapping active');
      });
  }

  ignore(event: Event, mappingId: string) {
    event.stopPropagation();
    this.store.dispatch(new UpdateLowConfidenceScore({ mappingId: mappingId, normalizationMappingUpdate: { ignored: true, updatedBy: JSON.parse(localStorage.getItem('user')).displayName } }));
    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoreSuccess))
      .subscribe((data: any) => {
        this.showMessage('success', 'Success', `Mapping is now ignored`);
        setTimeout(() => {
          // Refetch notifications every time the filter state changes
          this.store.select(getLowConfidenceScoreFilterState).pipe(first()).toPromise().then((data) => {
            this.store.dispatch(new FetchLowConfidenceScores(data));
            this.store.dispatch(new FetchLowConfidenceScoresCount());
            this.selectedMappings = [];
          });
        }, 3000);
      });

    this.actions
      .pipe(ofType(LowConfidenceScoreActionTypes.UpdateLowConfidenceScoresManyFailed))
      .subscribe((data: any) => {
        this.showMessage('error', 'Error', 'Failed to ignore mapping');
      });
  }

  isIgnored(record: any) {
    return record.ignored;
  }

  showMessage(severity: string, summary: string, detail: string) {
    this.messages = [
      {
        severity: severity,
        summary: summary,
        detail: detail,
      },
    ];
    this.resetMessages();
  }

  resetMessages() {
    setTimeout(() => {
      this.messages = [];
    }, 4000);
  }
}
